/* global clearInterval, console, CustomFunctions, setInterval, WebImageCellValue */

import moment from "moment";
import { utilsHelper } from "../../services/utilsHelper";

/**
 * 匹配正则表达式提取内容
 * @customfunction
 * @param {string} input text content for match
 * @param {string} pattern regex match pattern
 * @returns 返回匹配到的结果
 */
export function Regex(input: string, pattern: string): any {
  var regexp: RegExp = new RegExp(pattern);
  return regexp.exec(input)[0];
}

/**
 * 显示网络图片
 * @customfunction
 * @param {string} imagePath 网络图片URL地址（必须为https）
 * @returns 返回预览图片
 */
export function Image(imagePath: string): any {
  console.log(imagePath);
  return {
    type: "WebImage",
    address: imagePath,
  };
}

/**
 * 年终奖单独计税 VS 年终奖合并计税
 * @customfunction
 * @param yearincome 年累计税前收入
 * @param yearaward 年终奖
 * @param wuxianyijin 年累计五险一金
 * @param special 年累计特殊扣除
 * @param other 年其他累计扣除
 * @param isSeparete 是否单独计税
 * @returns 返回年累计缴税
 */
export function tax(
  yearincome: number,
  yearaward: number,
  wuxianyijin: number,
  special: number,
  other: number,
  isSeparete: boolean
): number {
  if (isSeparete) {
    let yearTax: number = taxCal(yearincome - 60000 - wuxianyijin - special - other);
    let awardTax: number = taxCal(yearaward, true);
    return yearTax + awardTax;
  } else {
    return taxCal(yearincome + yearaward - 60000 - wuxianyijin - special - other);
  }
}
function taxCal(taxincome: number, ismonthquick: boolean = false): number {
  let rate: number = 0;
  let quick: number = 0;
  switch (true) {
    case taxincome <= 0:
      rate = 0;
      quick = 0;
      break;
    case taxincome <= 36000:
      rate = 0.03;
      quick = 0;
      break;
    case taxincome <= 144000:
      rate = 0.1;
      quick = 2520;
      break;
    case taxincome <= 300000:
      rate = 0.2;
      quick = 16920;
      break;
    case taxincome <= 420000:
      rate = 0.25;
      quick = 31920;
      break;
    case taxincome <= 660000:
      rate = 0.3;
      quick = 52920;
      break;
    case taxincome <= 960000:
      rate = 0.35;
      quick = 85920;
      break;
    default:
      rate = 0.45;
      quick = 181920;
      break;
  }
  if (ismonthquick) {
    quick = quick / 12;
  }
  console.log(`${taxincome} * ${rate} - ${quick}`);
  return taxincome * rate - quick;
}

/**
 * Displays the current time once a second.
 * @customfunction
 * @param {string} [dateFormat] format for date
 * @param invocation Custom function handler
 * @returns value for clock
 */
export function clock(dateFormat: string, invocation: CustomFunctions.StreamingInvocation<string>): void {
  const timer: any = setInterval(() => {
    const time = new Date();
    invocation.setResult(formatDate(time, dateFormat));
  }, 1000);

  invocation.onCanceled = () => {
    clearInterval(timer);
  };
}

/**
 * now with date format
 * @customfunction
 * @param {string} [dateFormat] format for date result
 * @returns current time with specific format
 */
export function now(dateFormat: string) {
  if (dateFormat == null) {
    dateFormat = 'YYYY-MM-DD HH:mm:ss';
  }
  return format(jsDateToExcelDate(new Date()), dateFormat);
}

/**
 * format date with specific format
 * @customfunction
 * @param {any} data value for data
 * @param {string} format format for data
 * @returns formated date.
 */
export function formatDate(data: any, format: string): string {
  if (format == null) {
    format = 'YYYY-MM-DD HH:mm:ss';
  } 
  return moment(data).format(format);
}

/**
 * format value with specific format
 * @customfunction
 * @param {any} data value for data
 * @param {string} format format for data
 * @returns formated value.
 * @example 
 * Format a number with two decimal places
 * =format(1234.5678, "0.00")
 */
export function format(data: any,format: string) {
  return {
    type: Excel.CellValueType.formattedNumber,
    basicValue: data,
    numberFormat: format
  };
}

/**
 * convert js date to excel date
 * @param date js date
 * @returns excel date number
 */
function jsDateToExcelDate(date: Date): number{
  let excelDate = 25569.0 + ((date.getTime()-(date.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24));
  return excelDate;
}

/**
 * Increments a value once a second.
 * @customfunction
 * @param incrementBy Amount to increment
 * @param invocation Custom function handler
 */
export function increment(incrementBy: number, invocation: CustomFunctions.StreamingInvocation<number>): void {
  let result: number = 0;
  const timer: any = setInterval(() => {
    result += incrementBy;
    invocation.setResult(result);
  }, 1000);

  invocation.onCanceled = () => {
    clearInterval(timer);
  };
}

/**
 * convert value to format string
 * @customfunction
 * @param {number} value value to format
 * @param {string} format for value result,supported value: circled, rmb, chinese
 * @returns formated value
 */
export function toString(value:number, format: string): string{
  let result: string = value.toString();
  if (format.toLocaleLowerCase() == "circled") {
    result = utilsHelper.toCircledNumber(value);
  } else if(format.toLocaleLowerCase() == "rmb"){
    result = utilsHelper.toMoney(value);
  } else if(format.toLocaleLowerCase() == "chinese"){
    result = utilsHelper.toChinese(value);
  }
  return result;
}

/**
 * calculate formula string
 * @customfunction
 * @param {string} formula formula string
 * @returns calculated value
 */
export function calculate(formula: string): number | string {
  try {
    // Use eval to evaluate the formula (not recommended for untrusted input)
    let result = eval(formula);    
    // Check if result is NaN (Not a Number)
    if (isNaN(result)) {
        return 'Invalid expression';
    }    
    return result;
  } catch (error) {
      return 'Error: ' + error.message;
  }
}

/**
 * Writes a message to console.log().
 * @customfunction LOG
 * @param message String to write.
 * @returns String to write.
 */
export function logMessage(message: string): string {
  console.log(message);

  return message;
}

CustomFunctions.associate("REGEX", Regex);
CustomFunctions.associate("IMAGE", Image);
CustomFunctions.associate("TAX", tax);
CustomFunctions.associate("CLOCK", clock);
CustomFunctions.associate("NOW", now);
CustomFunctions.associate("FORMATDATE", formatDate);
CustomFunctions.associate("FORMAT", format);
CustomFunctions.associate("INCREMENT", increment);
CustomFunctions.associate("TOSTRING", toString);
CustomFunctions.associate("CALCULATE", calculate);
CustomFunctions.associate("LOG", logMessage);